import { faChevronRight } from "@fortawesome/free-solid-svg-icons/faChevronRight";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback } from "react";
import { Link } from "react-router-dom";

import BasicTooltip from "components/Common/Tooltip/BasicTooltip";

import EpisodeImage from "../EpisodeImage";
import PodcastImage from "../PodcastImage";

import getUserListUrl from "utils/entity/getUserListUrl";
import { capitalize } from "utils/misc";
import sendGAEvent from "utils/sendGAEvent";

import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import { createBreakpoint } from "styles/ScreenSizes";

const baseStyles = {
  similar: {
    display: "flex",
    alignItems: "Center",
    gap: "0.3rem",
  },
  button: {
    height: "1.5rem",
    width: "1.5rem",
    display: "grid",
    placeItems: "center",
    border: `1px solid ${colours.borderGrey}`,
    borderRadius: "0.25rem",
    fontSize: "0.813rem",

    ":hover": {
      cursor: "pointer",
    },
  },
  bottomRowContainer: {
    display: "grid",
    gridTemplateColumns: "90px 1fr",
    gridGap: "1rem",
    alignItems: "center",
    maxWidth: "100%",

    [createBreakpoint({ min: 1115 })]: {
      maxWidth: 370,
      display: "grid",
    },
  },
  bottomRowLabel: {
    ...gStyles.avalonBold,
    fontSize: "0.813rem",
    margin: 0,
    display: "flex",
    gap: "0.5rem",
  },
};

function EntityImageContainer({ entityType, entity, list }) {
  const entityClickHandler = useCallback(() => {
    sendGAEvent({
      action: "UserlistCardFeaturedEntityClick",
      listTitle: list?.get("title"),
      list_id: list?.get("id"),
      context: "UserlistCard",
      entityId: entity?.get("id"),
      entityTitle: entity?.get("title"),
      entityType,
    });
  }, [list, entity, entityType]);

  if (entityType === "podcast") {
    return (
      <PodcastImage
        key={entity?.get("id")}
        podcast={entity}
        onClick={entityClickHandler}
      />
    );
  }

  return (
    <EpisodeImage
      key={entity?.get("id")}
      episode={entity}
      onClick={entityClickHandler}
    />
  );
}

function ListCardEntities(props) {
  const { styles, css } = useStyles(baseStyles, props);
  const { list } = props;

  const entityType = list?.get("entity_type");
  const listUrl = getUserListUrl(list);
  const previewEntites = list?.get("preview_entities")?.slice(0, 6);

  const viewMoreClickHandler = useCallback(() => {
    sendGAEvent({
      action: "UserlistCardFeaturedEntitiesViewMoreClick",
      listTitle: list?.get("title"),
      list_id: list?.get("id"),
      context: "UserlistCard",
    });
  }, [list]);

  if (!previewEntites || previewEntites?.size === 0) {
    return null;
  }

  return (
    <div className={css(styles.bottomRowContainer, styles.largeOnly)}>
      <h3 className={css(styles.bottomRowLabel)}>
        {`${capitalize(entityType)}s`}
      </h3>
      <section className={css(styles.similar)}>
        {previewEntites?.map((entity) => {
          if (typeof entity !== "object") {
            return null;
          }

          return (
            <EntityImageContainer
              key={entity}
              entity={entity}
              entityType={entityType}
              list={list}
            />
          );
        })}
        <BasicTooltip renderTooltip={() => "View More"}>
          {(tooltipProps) => (
            <Link
              {...tooltipProps}
              to={listUrl}
              className={css(styles.button)}
              onClick={viewMoreClickHandler}
            >
              <FontAwesomeIcon icon={faChevronRight} dataid="viewMore" />
            </Link>
          )}
        </BasicTooltip>
      </section>
    </div>
  );
}

export default ListCardEntities;
