import { faHeart } from "@fortawesome/free-solid-svg-icons/faHeart";
import { faCircleNotch } from "@fortawesome/pro-light-svg-icons/faCircleNotch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback } from "react";
import { setLikedPromise as setLikedPromiseRoutine } from "routines/likes";

import BasicTooltip from "components/Common/Tooltip/BasicTooltip";

import { selectLoggedInUserHasLikedList } from "selectors/userlist";
import sendGAEvent from "utils/sendGAEvent";

import useReduxState from "hooks/useReduxState";
import useRoutinePromises from "hooks/useRoutinePromises";
import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";

const baseStyles = {
  button: {
    height: "2rem",
    width: "2rem",
    display: "grid",
    placeItems: "center",
    border: `1px solid ${colours.borderGrey}`,
    outline: "none",
    borderRadius: "25px",
    fontSize: "0.813rem",

    ":hover": {
      cursor: "pointer",
      backgroundColor: "#f7f7f7",
    },
  },
  liked: {
    border: "none",
    background: "var(--color-primary-d10)",
    color: colours.primary,

    ":hover": {
      background: "var(--color-primary-d10)",
    },
  },
};

function LikeListAction(props) {
  const { styles, css } = useStyles(baseStyles, props);
  const { list } = props;

  const { setLikedPromise } = useRoutinePromises({
    setLikedPromise: setLikedPromiseRoutine,
  });
  const { userHasLiked, savingLike } = useReduxState(
    (state) => ({
      userHasLiked:
        list && selectLoggedInUserHasLikedList(state, list.get("id")),
      savingLike: list && list.getIn(["user_data", "like_saving"]),
    }),
    [list]
  );

  const handleClick = useCallback(() => {
    sendGAEvent({
      action: "UserlistCardLikeButtonClicked",
      listTitle: list?.get("title"),
      listId: list?.get("id"),
      state: userHasLiked ? "unliked" : "liked",
    });

    setLikedPromise({
      entity_id: list.get("id"),
      entity_type: "userlist",
      entity: list,
      liked: !userHasLiked,
    });
  }, [list, setLikedPromise, userHasLiked]);

  if (!list) {
    return null;
  }

  return (
    <BasicTooltip
      renderTooltip={() => `${userHasLiked ? "Unlike" : "Like"} this list`}
    >
      {(tooltipProps) => (
        <button
          {...tooltipProps}
          className={css(styles.button, userHasLiked && styles.liked)}
          onClick={handleClick}
        >
          <FontAwesomeIcon
            icon={savingLike ? faCircleNotch : faHeart}
            dataid="like"
          />
        </button>
      )}
    </BasicTooltip>
  );
}

export default LikeListAction;
