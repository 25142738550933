import { selectSpecificUser } from "selectors/user";

import useReduxState from "hooks/useReduxState";

function useListOwner(list) {
  return useReduxState(
    (state) => {
      if (!list || !list.get("owners")) {
        return null;
      }
      return selectSpecificUser(state, list.get("owners").first());
    },
    [list]
  );
}

export default useListOwner;
