import { Map } from "immutable";
import PropTypes from "prop-types";
import { useMemo } from "react";

import ListImage from "components/Entities/Images/ListImageAsync";

import { useStyles } from "hooks/useStyles";

const baseStyles = {
  entityImage: {
    position: "relative",
    display: "block",
    transform: "translate3d(0, 0, 0)",
    backfaceVisibility: "hidden",
    height: 73,
    width: 73,
    minWidth: 73,
    borderRadius: 4,
    backgroundColor: "transparent",
  },
  insetShadow: {
    position: "absolute",
    width: "100%",
    height: "100%",
    boxShadow: "inset 0 0 2px 0 rgba(0,0,0,0.4)",
    top: 0,
    left: 0,
    pointerEvents: "none",
    borderRadius: 4,
  },
  contentWithChildren: {
    position: "relative",
    background: "rgba(0,0,0,0.04)",
    width: "100%",
    aspectRatio: "1 / 1",
    borderRadius: 4,
    overflow: "hidden",
  },
};

export default function UserlistImage(props) {
  const { styles } = useStyles([baseStyles], props);
  const { entity, size: passedSize, renderWithContainers } = props;

  const size = useMemo(() => passedSize || 73, [passedSize]);

  return (
    <ListImage
      pxSize={size}
      renderWithContainers={renderWithContainers}
      entity={entity}
      containerClasses={[styles.entityImage]}
    />
  );
}

UserlistImage.propTypes = {
  entity: PropTypes.instanceOf(Map),
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  renderWithContainers: PropTypes.func,
};

UserlistImage.defaultProps = {
  entity: null,
  size: null,
  renderWithContainers: null,
};
