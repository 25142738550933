import React, { useCallback } from "react";
import { Link } from "react-router-dom";

import PodcastProfileHoverPopover from "components/Profiles/PodcastProfileHoverPopover";

import cachedImage from "utils/entity/cachedImage";
import getEntityImageTitle, {
  getEntityImageAlt,
} from "utils/entity/getEntityImageTitle";

import usePodcastUrl from "hooks/usePodcastUrl";
import { useStyles } from "hooks/useStyles";

const baseStyles = {
  image: {
    height: "1.5rem",
    width: "1.5rem",
    background: "rgba(0,0,0,0.04)",
    borderRadius: 4,
  },
};

export default function PodcastImage(props) {
  const { styles, css } = useStyles(baseStyles, props);
  const { podcast, onClick } = props;

  const MAIN_SIZE_IMAGE = 64;
  const src = podcast.get("image_url");
  const alt = getEntityImageAlt(podcast, "podcast");
  const title = getEntityImageTitle(podcast, "podcast");
  const podcastHeaderImageUrl = cachedImage(src, MAIN_SIZE_IMAGE);
  const podcastHeaderImageUrlRetina = cachedImage(src, MAIN_SIZE_IMAGE * 2);
  const podcastUrl = usePodcastUrl(podcast);

  const handleClick = useCallback(() => {
    if (onClick) {
      onClick();
    }
  }, [onClick]);

  return (
    <Link to={podcastUrl} onClick={handleClick}>
      <PodcastProfileHoverPopover entity_id={podcast.get("id")} inline>
        <img
          src={podcastHeaderImageUrl}
          srcSet={`${podcastHeaderImageUrl} 1x, ${podcastHeaderImageUrlRetina} 2x`}
          className={css(styles.image)}
          alt={alt}
          title={title}
        />
      </PodcastProfileHoverPopover>
    </Link>
  );
}
