import { makeSelectUserEpisodeToggleStatus } from "../selectors/user";

import useLoggedInUser from "hooks/useLoggedInUser";
import useReduxState from "hooks/useReduxState";

function useEpisodeTranscriptToggle() {
  const user = useLoggedInUser();
  const userId = user?.get("id");

  const selectUserEpisodeToggleStatus = makeSelectUserEpisodeToggleStatus();

  const showingTranscript = useReduxState(
    (state) => selectUserEpisodeToggleStatus(state, userId),
    [userId]
  );

  return showingTranscript;
}

export default useEpisodeTranscriptToggle;
