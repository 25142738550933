import PropTypes from "prop-types";

import { selectListIds } from "selectors/pagination";

import useReduxState from "hooks/useReduxState";

const FilteredListContainer = (props) => {
  const { render, children: passedChildren, listKey } = props;
  const children = passedChildren || render;

  const ids = useReduxState(
    (state) => selectListIds(state, listKey),
    [listKey]
  );

  return children({ ids }) || null;
};

FilteredListContainer.propTypes = {
  listKey: PropTypes.string.isRequired,
  render: PropTypes.func,
  children: PropTypes.func,
};

FilteredListContainer.defaultProps = {
  render: null,
  children: null,
};

export default FilteredListContainer;
