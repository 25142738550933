import React, { useCallback } from "react";
import { Link } from "react-router-dom";

import cachedImage from "utils/entity/cachedImage";
import getEntityImageTitle, {
  getEntityImageAlt,
} from "utils/entity/getEntityImageTitle";
import getEpisodeUrl from "utils/entity/getEpisodeUrl";

import { useStyles } from "hooks/useStyles";

const baseStyles = {
  image: {
    height: "1.5rem",
    width: "1.5rem",
    background: "rgba(0,0,0,0.04)",
    borderRadius: 4,
  },
};

export default function EpisodeImage(props) {
  const { styles, css } = useStyles(baseStyles, props);
  const { episode, onClick } = props;

  const MAIN_SIZE_IMAGE = 64;
  const src = episode.get("image_url");
  const alt = getEntityImageAlt(episode, "episode");
  const title = getEntityImageTitle(episode, "episode");
  const episodeHeaderImageUrl = cachedImage(src, MAIN_SIZE_IMAGE);
  const episodeHeaderImageUrlRetina = cachedImage(src, MAIN_SIZE_IMAGE * 2);
  const episodeUrl = getEpisodeUrl(episode);

  const handleClick = useCallback(() => {
    if (onClick) {
      onClick();
    }
  }, [onClick]);

  return (
    <Link to={episodeUrl} onClick={handleClick}>
      <img
        src={episodeHeaderImageUrl}
        srcSet={`${episodeHeaderImageUrl} 1x, ${episodeHeaderImageUrlRetina} 2x`}
        className={css(styles.image)}
        alt={alt}
        title={title}
      />
    </Link>
  );
}
