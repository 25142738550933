import { useMemo } from "react";

import EpisodesAddToListMenu from "components/Entities/Cards/EpisodeCards/EpisodesAddToListMenu";

import useEpisodeData from "hooks/useEpisodeData";
import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";

const baseStyles = {
  addToListIcon: {
    border: "none",
    margin: 0,
    padding: 0,

    ":hover": {
      cursor: "pointer",
    },
  },
  full: {
    width: "max-content",
    height: "2rem",
    padding: "0 1rem",
    display: "flex",
    alignItems: "center",
    gap: "0.5rem",
    borderRadius: 25,
    border: `1px solid ${colours.borderGrey}`,
    ...gStyles.avalonBold,
    fontSize: "0.813rem",
  },
  onList: {
    color: colours.primary,
    background: "var(--color-primary-d10)",
    borderRadius: 25,
    border: "none",
  },
};

const ELLIPSIS_STYLES = {
  ellipsisPopoverContainer: {
    minWidth: "max-content",
  },
  ellipsisButtonInner: {
    width: "max-content",
    height: "2rem",
    padding: "0 0.5rem",
    display: "flex",
    gap: "0.5rem",
    alignItems: "center",
    borderRadius: 25,
    border: `1px solid ${colours.borderGrey}`,
    fontSize: "0.813rem",
    color: colours.black,
  },
  buttonContainer: {
    marginRight: 0,
  },
};
const ELLIPSIS_STYLES_ACTIVE = {
  ...ELLIPSIS_STYLES,
  ellipsisButtonInner: {
    width: "max-content",
    height: "2rem",
    padding: "0 0.5rem",
    display: "flex",
    gap: "0.5rem",
    alignItems: "center",
    borderRadius: 25,
    border: "none",
    backgroundColor: "transparent",
    fontSize: "0.813rem",
    color: colours.primary,
  },
};

export default function AddToListEpisode(props) {
  const {
    episodeId,
    withLabel,
    analyticsProps,
    passedEllipsisButtonStyles,
    passedEllipsisActiveButtonStyles,
  } = props;

  const episode = useEpisodeData(episodeId);

  const { styles, css } = useStyles(baseStyles, props);

  const isInList = useMemo(() => {
    const userData = episode?.get("user_data");
    const listArray = userData?.get("in_lists");

    return listArray?.size > 0;
  }, [episode]);

  const FINAL_ELLIPSIS_STYLES = useMemo(
    () => ({
      ...ELLIPSIS_STYLES,
      ...passedEllipsisButtonStyles,
    }),
    [passedEllipsisButtonStyles]
  );

  const FINAL_ELLIPSIS_STYLES_ACTIVE = useMemo(
    () => ({
      ...ELLIPSIS_STYLES_ACTIVE,
      ...passedEllipsisActiveButtonStyles,
    }),
    [passedEllipsisActiveButtonStyles]
  );

  const label = useMemo(() => {
    if (isInList) {
      return "Added";
    }

    return "Add to List";
  }, [isInList]);

  return (
    <button
      data-testid="addToList"
      className={css(
        styles.addToListIcon,
        withLabel && styles.full,
        isInList && styles.onList
      )}
    >
      <EpisodesAddToListMenu
        episode={episode}
        passedLabel={withLabel && label}
        closeOnOutsideclick
        analyticsProps={analyticsProps}
        passedEllipsisButtonStyles={
          isInList ? FINAL_ELLIPSIS_STYLES_ACTIVE : FINAL_ELLIPSIS_STYLES
        }
      />
    </button>
  );
}
