import { faDownload } from "@fortawesome/free-solid-svg-icons/faDownload";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback } from "react";

import BasicTooltip from "components/Common/Tooltip/BasicTooltip";

import modalActions from "actions/modals";
import { getBaseUrl } from "constants/base";
import sendGAEvent from "utils/sendGAEvent";

import useActionCreators from "hooks/useActionCreators";
import useLoggedInUser from "hooks/useLoggedInUser";
import { useStyles } from "hooks/useStyles";
import useUserHasPro from "hooks/useUserHasPro";

import colours from "styles/colours";

const baseStyles = {
  button: {
    height: "2rem",
    width: "2rem",
    display: "grid",
    placeItems: "center",
    border: `1px solid ${colours.borderGrey}`,
    outline: "none",
    borderRadius: "25px",
    fontSize: "0.813rem",

    ":hover": {
      cursor: "pointer",
      backgroundColor: "#f7f7f7",
    },
  },
};

function ListExportAction(props) {
  const { styles, css } = useStyles(baseStyles, props);
  const { list } = props;

  const listPrivacy = list?.get("privacy");
  const listLinkOnlyId = list?.get("link_only_id");
  const entityType = list?.get("entity_type");
  const listTitle = list && list?.get("title");
  const listId = list && list?.get("id");

  const hasPro = useUserHasPro();
  const { showModal } = useActionCreators({
    showModal: modalActions.showModal,
  });

  const loggedInUser = useLoggedInUser();

  const linkUrlId = listPrivacy === "link_only" ? listLinkOnlyId : listId;

  const handeClick = useCallback(() => {
    sendGAEvent({
      action: "UserlistCardExportButtonClicked",
      actionCarriedOutBy: loggedInUser?.get("display_name"),
      context: "UserListPage",
      listTitle,
      listId,
      entityType,
    });

    if (entityType === "episode") {
      showModal("listDownloadModal", {
        link: `${getBaseUrl()}/export/list/${linkUrlId}/rss`,
        buttonLabel: "RSS",
        downLoadTitle: "Your RSS file is ready.",
        downloadKey: "rss",
      });
    } else {
      if (hasPro) {
        showModal("downloadContacts", {
          list_id: listId,
          context: "userlistHeaderExportButton",
          stage: "format_selection",
        });
      } else {
        showModal("publicDownloadContacts", {
          list_id: listId,
          context: "userlistHeaderExportButton",
          stage: "format_selection",
        });
      }
    }
  }, [
    entityType,
    hasPro,
    linkUrlId,
    listId,
    listTitle,
    loggedInUser,
    showModal,
  ]);

  if (!list) {
    return null;
  }

  return (
    <BasicTooltip renderTooltip={() => "Export this list"}>
      {(tooltipProps) => (
        <button
          {...tooltipProps}
          className={css(styles.button)}
          onClick={handeClick}
        >
          <FontAwesomeIcon icon={faDownload} dataid="export" />
        </button>
      )}
    </BasicTooltip>
  );
}

export default ListExportAction;
