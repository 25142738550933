import { css } from "aphrodite";
import { Map } from "immutable";
import PropTypes from "prop-types";
import { useCallback } from "react";
import { Link } from "react-router-dom";

import CardFollowAction from "components/Common/table/commons/CardFollowAction";
import HighLightText from "components/Highlight/HighLightText";

import ListProfileHoverPopover from "../../../Profiles/ListProfileHoverPopoverAsync";
import UserlistImageContainer from "../UserlistImageContainer";
import LikeListAction from "./LikeListAction";
import ListCardCategories from "./ListCardCategories";
import ListCardDescription from "./ListCardDescription";
import ListCardEntities from "./ListCardEntities";
import ListCardStats from "./ListCardStats";
import ListEditCardAction from "./ListEditCardAction";
import ListExportAction from "./ListExportAction";
import UserlistEllipsisMenu from "./UserlistEllipsisMenu";

import getUserListUrl from "utils/entity/getUserListUrl";
import { capitalize } from "utils/misc";
import sendGAEvent from "utils/sendGAEvent";

import { useStyles } from "hooks/useStyles";

import cardStyles from "styles/CardStyles";
import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import ScreenSizes, { createBreakpoint } from "styles/ScreenSizes";

const baseStyles = {
  listDesktopCard: {
    ...cardStyles.mobileCard,
    minHeight: "13rem",
    padding: "1.5rem 1rem",
    border: `1px solid var(--color-neutral-d85)`,
    borderRadius: "0.75rem",

    [ScreenSizes.mdAndAbove]: {
      ...cardStyles.desktopCard,
      padding: "1.5rem",
      maxWidth: "54rem",
      marginLeft: "auto",
      marginRight: "auto",
      borderRadius: 8,
    },
  },
  topFirstRow: {
    display: "grid",
    gridTemplateColumns: "max-content 1fr max-content",
    gap: "1rem",
    marginBottom: "1rem",
  },
  topFirstRowCol1: {
    display: "grid",
    gridTemplateRows: "repeat(3, max-content)",
    gap: "0.313rem",
    alignContent: "space-between",
  },
  topActions: {
    display: "none",

    [createBreakpoint({ min: 1115 })]: {
      display: "flex",
      gap: "0.5rem",
      alignItems: "center",
      height: "2rem",
      width: "100%",
    },
  },
  bottomActions: {
    display: "flex",
    gap: "0.5rem",
    alignItems: "center",
    height: "max-content",
    width: "100%",
    marginTop: "1rem",
    paddingTop: "1.5rem",
    borderTop: `1px solid ${colours.borderGrey}`,

    [createBreakpoint({ min: 1115 })]: {
      display: "none",
    },
  },
  bottomRow: {
    ...cardStyles.bottomRow,
    display: "grid",
    gridTemplateColumns: "1fr",
    gridGap: "1rem",
    alignItems: "center",

    [createBreakpoint({ min: 1115 })]: {
      gridTemplateColumns: "1fr 1fr",
    },
  },
  listType: {
    ...gStyles.avalonBold,
    fontSize: 11,
    color: colours.black,
    margin: 0,
    padding: 0,
  },
  titleContainer: {
    display: "flex",
    gap: 5,
    alignItems: "center",
    flexDirection: "row",
  },
  title: {
    ...cardStyles.mobileCardPrimaryTitle,
    marginBottom: 0,
    fontSize: 20,
    lineHeight: "22px",
  },
};

const ellipsisStyles = {
  actionButtonsContainer: {
    borderRadius: "25px",
  },
  ellipsisButtonInner: {
    fontSize: "0.75rem",
    height: "2rem",
    width: "2rem",
    borderRadius: "25px",
    border: `1px solid ${colours.borderGrey}`,
  },
  ellipsisButtonWrapper: {
    marginLeft: "auto",

    [createBreakpoint({ min: 1115 })]: {
      marginLeft: 0,
    },
  },
};

const inPanelStyles = {
  listDesktopCard: {
    ...cardStyles.noRaisedCard,
    ...cardStyles.flatCard,
  },
  bottomRow: {
    ...cardStyles.bottomRowInsidePanel,
  },
};

const ListDesktopCard = (props) => {
  const {
    list,
    noPanel,
    insidePanel,
    renderEntityImageOverlay,
    principalSearchTerm,
    secondarySearchTerm,
  } = props;

  const { styles } = useStyles(
    [baseStyles, insidePanel && inPanelStyles],
    props
  );

  const listUrl = list && getUserListUrl(list);
  const listType = list?.get("entity_type");
  const handleClick = useCallback(() => {
    sendGAEvent({
      action: "titleClicked",
      entityId: list?.get("id"),
      entity_name: list?.get("title"),
      context: "Title Click",
    });
  }, [list]);

  const renderTopRow = () => (
    <div className={css(styles.topFirstRow)}>
      <UserlistImageContainer
        entity={list}
        renderEntityImageOverlay={renderEntityImageOverlay}
      />
      <div className={css(styles.topFirstRowCol1)}>
        <h3 className={css(styles.listType)}>{`${capitalize(
          listType
        )} List`}</h3>
        <ListProfileHoverPopover list={list}>
          <span className={css(styles.titleContainer)} onClick={handleClick}>
            <Link className={css(styles.title)} to={listUrl}>
              <HighLightText
                textToHighlight={list?.get("title")}
                principalSearchTerm={principalSearchTerm}
                secondarySearchTerm={secondarySearchTerm}
              />
            </Link>
          </span>
        </ListProfileHoverPopover>

        <ListCardStats listId={list?.get("id")} />
      </div>
      <div className={css(styles.topActions)}>
        <ListEditCardAction listUrl={listUrl} listId={list?.get("id")} />
        <CardFollowAction entity={list} entity_type="userlist" />
        <LikeListAction list={list} />
        <ListExportAction list={list} />
        <UserlistEllipsisMenu
          list={list}
          id="ellipsis"
          styles={ellipsisStyles}
          closeOnOutsideclick
        />
      </div>
    </div>
  );

  const renderMiddleRow = () => (
    <div className={css(styles.middleRow)}>
      <ListCardDescription
        list={list}
        principalSearchTerm={principalSearchTerm}
        secondarySearchTerm={secondarySearchTerm}
      />
    </div>
  );

  const renderBottomRow = () => (
    <>
      <div
        className={css(
          styles.bottomRow,
          insidePanel && styles.bottomRowInsidePanel
        )}
      >
        <ListCardCategories list={list} />
        <ListCardEntities list={list} />
      </div>
      <div className={css(styles.bottomActions)}>
        <CardFollowAction entity={list} entity_type="userlist" />
        <LikeListAction list={list} />
        <ListExportAction list={list} />
        <ListEditCardAction listUrl={listUrl} listId={list?.get("id")} />
        <UserlistEllipsisMenu
          list={list}
          id="ellipsis"
          styles={ellipsisStyles}
          closeOnOutsideclick
        />
      </div>
    </>
  );

  if (!list) {
    return null;
  }

  return (
    <div
      data-id="list-desktop-card"
      className={css(
        styles.listDesktopCard,
        noPanel && styles.listDesktopCardNoPanel,
        styles.entityCard
      )}
    >
      {renderTopRow()}
      {renderMiddleRow()}
      {renderBottomRow()}
    </div>
  );
};

ListDesktopCard.propTypes = {
  insidePanel: PropTypes.bool,
  list: PropTypes.instanceOf(Map),
  noPanel: PropTypes.bool,
  renderEntityImageOverlay: PropTypes.func,
  renderOnMount: PropTypes.bool,
};

export default ListDesktopCard;
