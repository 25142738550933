import PropTypes from "prop-types";
import { memo } from "react";

import ListDesktopCard from "./ListDesktopCard";

import { selectSpecificList } from "selectors/userlist";

import useListOwner from "hooks/useListOwner";
import useReduxState from "hooks/useReduxState";

const ListDesktopCardContainer = (props) => {
  const { list_id } = props;
  const list = useReduxState(
    (state) => selectSpecificList(state, list_id),
    [list_id]
  );

  const owner = useListOwner(list);

  return <ListDesktopCard {...props} list={list} owner={owner} />;
};

ListDesktopCardContainer.propTypes = {
  list_id: PropTypes.number,
};

ListDesktopCardContainer.defaultProps = {};

export default memo(ListDesktopCardContainer);
