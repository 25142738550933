import { call, cancel, fork, take } from "redux-saga/effects";

import loadList, { getApiProps } from "./loadList";

import * as userlistApi from "api/live/userlist";
import * as paginationConstants from "constants/pagination";
import { apiCall } from "sagas/api";

export default function* loadUserlistsList(action) {
  const { apiProps, pagination } = yield call(getApiProps, action);

  const response = yield call(apiCall, {
    fn: userlistApi.loadUserListFilterList,
    data: apiProps,
  });

  yield call(apiCall, {
    fn: loadList,
    data: {
      list_type: "userlists_filtered",
      action,
      pagination,
      apiProps,
      response,
    },
  });
}

export function* loadUserlistsListWatch() {
  const tasksByKey = {};

  while (true) {
    const action = yield take(paginationConstants.LOAD_USER_LISTS_LIST);
    if (tasksByKey[action.key]) {
      yield cancel(tasksByKey[action.key]);
    }
    tasksByKey[action.key] = yield fork(loadUserlistsList, action);
  }
}

loadUserlistsListWatch.displayName = "loadUserlistsListWatch";
