import { useCallback } from "react";
import { setLiked as setLikedRoutine } from "routines/likes";

import UserListsHub from "./UserListsHub";

import paginationActions from "actions/pagination";
import * as sortConstants from "constants/sort";
import loadUserlistsList from "sagas/pagination/lists/loadUserlistsList";
import { selectListLoaded, selectListLoading } from "selectors/pagination";

import useActionCreators from "hooks/useActionCreators";
import useExecOnMount from "hooks/useExecOnMount";
import useReduxState from "hooks/useReduxState";
import useWindowSize from "hooks/useWindowSize";

const UserListsHubContainer = (props) => {
  const { initList, setLiked } = useActionCreators({
    initList: paginationActions.initList,
    setLiked: setLikedRoutine,
  });

  const { loading, loaded } = useReduxState(
    (state) => ({
      loading:
        selectListLoading(state, { key: "lists_hub_trending" }) ||
        selectListLoading(state, { key: "lists_hub_recent" }) ||
        selectListLoading(state, { key: "lists_hub_most_liked" }),
      loaded:
        selectListLoaded(state, { key: "lists_hub_trending" }) ||
        selectListLoaded(state, { key: "lists_hub_recent" }) ||
        selectListLoaded(state, { key: "lists_hub_most_liked" }),
    }),
    []
  );

  const { isWindowSizeOrMore } = useWindowSize();
  const isExtraLarge = isWindowSizeOrMore("xlarge");

  const loadLists = useCallback(() => {
    // load first page based on filters
    initList({
      key: "lists_hub_trending",
      sort: sortConstants.SORT_ORDER_TRENDING,
      list_type: "lists_filtered",
      loadListAction: loadUserlistsList,
      entity_type: "userlist",
      pageSize: isExtraLarge ? 6 : 4,
    });
    initList({
      key: "lists_hub_recent",
      sort: sortConstants.SORT_ORDER_RECENT,
      list_type: "lists_filtered",
      loadListAction: loadUserlistsList,
      entity_type: "userlist",
      pageSize: 5,
    });
    initList({
      key: "lists_hub_most_liked",
      sort: sortConstants.SORT_ORDER_RANKING,
      list_type: "lists_filtered",
      loadListAction: loadUserlistsList,
      entity_type: "userlist",
      pageSize: 8,
    });
  }, [initList, isExtraLarge]);

  useExecOnMount(loadLists, !loading && !loaded);

  const toggleLike = useCallback(
    (list) =>
      setLiked({
        entity_id: list.get("id"),
        entity_type: "userlist",
        entity: list,
        liked: !(list.getIn(["user_data", "is_liked"]) || false),
      }),
    [setLiked]
  );

  return <UserListsHub {...props} toggleListLike={toggleLike} />;
};

export default UserListsHubContainer;
