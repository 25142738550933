import { Map } from "immutable";
import PropTypes from "prop-types";
import { Fragment } from "react";

import UserlistImage from "./UserlistImage";

import { selectSpecificPodcast } from "selectors/podcast";

import useReduxState from "hooks/useReduxState";

export default function UserlistImageContainer(props) {
  const { entity: passedEntity, renderEntityImageOverlay, size } = props;

  let entity = passedEntity;

  const podcast = useReduxState(
    (state) =>
      selectSpecificPodcast(state, entity ? entity.get("podcast_id") : null),
    [entity]
  );

  if (podcast) {
    entity = entity.set("podcast", podcast);
  }

  const renderWithContainers = (content) => {
    return (
      <Fragment>
        {content}
        {renderEntityImageOverlay && renderEntityImageOverlay(entity)}
      </Fragment>
    );
  };

  return (
    <UserlistImage
      entity={entity}
      renderWithContainers={renderWithContainers}
      size={size}
      styles={props?.styles}
    />
  );
}

UserlistImageContainer.propTypes = {
  entity_type: PropTypes.string,
  entity: PropTypes.instanceOf(Map),
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  renderEntityImageOverlay: PropTypes.func,
};

UserlistImageContainer.defaultProps = {
  entity: null,
  entity_type: "",
  size: null,
  renderEntityImageOverlay: null,
};
