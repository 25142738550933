import { css } from "aphrodite";
import PropTypes from "prop-types";

import { useStyles } from "hooks/useStyles";

import gStyles from "styles/GenericStyles";

const baseStyles = {
  titleWrapper: {
    ...gStyles.primaryPageTitle,
  },
};

const PageTitle = (props) => {
  const { title, TitleComponent, dataId, children } = props;
  const { styles } = useStyles(baseStyles, props);

  return (
    <TitleComponent data-id={dataId} className={css(styles.titleWrapper)}>
      {title || children}
    </TitleComponent>
  );
};

PageTitle.propTypes = {
  title: PropTypes.string,
  TitleComponent: PropTypes.string,
  dataId: PropTypes.string,
  children: PropTypes.node,
};

PageTitle.defaultProps = {
  title: null,
  TitleComponent: "h1",
  dataId: null,
  children: null,
};

export default PageTitle;
