import { css } from "aphrodite";
import { Map } from "immutable";
import PropTypes from "prop-types";
import { memo, useCallback } from "react";
import { Link } from "react-router-dom";

import ListProfileHoverPopover from "../../Profiles/ListProfileHoverPopoverAsync";
import ListCardCurator from "../Cards/ListCards/ListCardCurator";
import ListCardStats from "../Cards/ListCards/ListCardStats";
import UserlistImageContainer from "../Cards/UserlistImageContainer";

import getUserListUrl from "utils/entity/getUserListUrl";
import { capitalize } from "utils/misc";
import mergeDeep from "utils/objects/mergeDeep";
import sendGAEvent from "utils/sendGAEvent";

import { useStyles } from "hooks/useStyles";

import cardStyles, { raisedCardStyles } from "styles/CardStyles";
import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  container: {
    ...cardStyles.mobileCard,
    ...raisedCardStyles,
    minHeight: "13rem",
    minWidth: "14.375rem",
    padding: "1.5rem 1rem",
    border: `1px solid var(--color-neutral-d85)`,
    borderRadius: "0.75rem",
    overflow: "hidden",

    [ScreenSizes.mdAndAbove]: {
      ...cardStyles.desktopCard,
      padding: "1.5rem",
      maxWidth: "16rem",
      borderRadius: 8,
    },
  },
  listItem: {
    display: "grid",
    gridTemplateRows: "repeat(5, max-content)",
    gridGap: "1rem",
  },
  listType: {
    ...gStyles.avalonBold,
    fontSize: 11,
    color: colours.black,
    margin: 0,
    padding: 0,
    textAlign: "start",
  },
  titleContainer: {
    display: "flex",
    gap: 5,
    alignItems: "center",
    flexDirection: "row",
  },
  title: {
    ...cardStyles.mobileCardPrimaryTitle,
    marginBottom: 0,
    fontSize: 20,
    lineHeight: "22px",
  },
};

const defaultImageStyles = {
  entityImage: {
    ...cardStyles.desktopCardEntityImage,
    background: "none",
    margin: 0,
    height: 138,
    width: 138,
    minWidth: 138,
  },
};

const curatorStyles = {
  curator: {
    maxWidth: 180,
  },
};

const UserlistFeatureItem = (props) => {
  const { list, passedImageStyles, dontShowListType, dontShowAdditional } =
    props;

  const { styles } = useStyles(baseStyles, props);

  const listType = list?.get("entity_type");
  const listUrl = getUserListUrl(list);

  const listImageStyles = passedImageStyles
    ? mergeDeep(defaultImageStyles, passedImageStyles)
    : defaultImageStyles;

  const handleClick = useCallback(() => {
    sendGAEvent({
      action: "titleClicked",
      entityId: list?.get("id"),
      entity_name: list?.get("title"),
      context: "Title Click",
    });
  }, [list]);

  if (!list) {
    return null;
  }

  return (
    <div className={css(styles.container)}>
      <div data-id="userlist-item" className={css(styles.listItem)}>
        <UserlistImageContainer
          entity={list}
          size={138}
          styles={listImageStyles}
        />
        {!dontShowListType && (
          <h3 className={css(styles.listType)}>{`${capitalize(
            listType
          )} List`}</h3>
        )}
        <ListProfileHoverPopover list={list}>
          <span className={css(styles.titleContainer)} onClick={handleClick}>
            <Link className={css(styles.title)} to={listUrl}>
              {list?.get("title")}
            </Link>
          </span>
        </ListProfileHoverPopover>
        {!dontShowAdditional && (
          <>
            <ListCardStats listId={list?.get("id")} />
            <ListCardCurator listId={list?.get("id")} styles={curatorStyles} />
          </>
        )}
      </div>
    </div>
  );
};

UserlistFeatureItem.propTypes = {
  list: PropTypes.instanceOf(Map).isRequired,
};

export default memo(UserlistFeatureItem);
