/* eslint-disable react/display-name */
import { css } from "aphrodite";
import { Map } from "immutable";
import PropTypes from "prop-types";
import { memo, useCallback, useMemo } from "react";

import EllipsisMenuButton from "components/Buttons/EllipsisMenuButton/EllipsisMenuButton";
import EllipsisMenuItem from "components/Buttons/EllipsisMenuButton/EllipsisMenuItem";

import modalActions from "actions/modals";
import sendGAEvent from "utils/sendGAEvent";

import useActionCreators from "hooks/useActionCreators";
import { useStyles } from "hooks/useStyles";

import faAddToList from "styles/icons/faAddToList";

const baseStyles = {
  column: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
};

const ELLIPSIS_STEPS = ["default", "share", "external"];

const EpisodesAddToListMenu = (props) => {
  const {
    episode,
    closeOnOutsideclick,
    pageType,
    context,
    passedEllipsisButtonStyles,
    analyticsProps,
    passedLabel,
  } = props;
  const { styles } = useStyles(baseStyles, props);

  const { showModal } = useActionCreators(modalActions);

  const analyticsVariables = useMemo(
    () => ({
      ...(analyticsProps || {}),
      ellipsisMenuType: "EpisodesAddToListButtonClicked",
      episode_id: episode && episode?.get("id"),
      episode_title: episode && episode?.get("title"),
    }),
    [analyticsProps, episode]
  );

  const preventDefault = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleAddToListClick = useCallback(
    ({ entity, entityType, entityId }) => {
      showModal("prompt", {
        promptType: "list",
        entity: entity,
        entity_type: entityType,
        page: pageType,
        context,
      });

      sendGAEvent({
        action: "cardActionClick",
        entity_name: entityType,
        entity_id: entityId,
        entityTypeSelected: entityType,
        pageType,
        ...(analyticsProps || {}),
      });
    },
    [analyticsProps, context, pageType, showModal]
  );

  const handleClick = useCallback(
    (type) => {
      const podcast = episode?.get("podcast");
      if (type === "episode") {
        handleAddToListClick({
          entityType: "episode",
          entity: episode,
          entityId: episode?.get("id"),
        });
      } else {
        handleAddToListClick({
          entityType: "podcast",
          entity: podcast,
          entityId: podcast?.get("id"),
        });
      }
    },
    [episode, handleAddToListClick]
  );

  const renderContent = useCallback(() => {
    return (
      <div key="default" className={css(styles.column)}>
        <EllipsisMenuItem
          key="addEpisode"
          entity={episode}
          entity_type="episode"
          label="Add Episode"
          onClick={() => handleClick("episode")}
          onMouseDown={preventDefault}
          onMouseUp={preventDefault}
        />
        <EllipsisMenuItem
          key="addPodcast"
          entity={episode}
          entity_type="episode"
          label="Add Podcast"
          onClick={() => handleClick("podcast")}
          onMouseDown={preventDefault}
          onMouseUp={preventDefault}
        />
      </div>
    );
  }, [styles.column, episode, handleClick]);

  return (
    <EllipsisMenuButton
      renderContent={renderContent}
      steps={ELLIPSIS_STEPS}
      analyticsVariables={analyticsVariables}
      passedEllipsisButtonStyles={passedEllipsisButtonStyles}
      closeOnOutsideclick={closeOnOutsideclick}
      styles={passedEllipsisButtonStyles}
      passedIcon={faAddToList}
      passedLabel={passedLabel}
    />
  );
};

EpisodesAddToListMenu.propTypes = {
  episode: PropTypes.instanceOf(Map).isRequired,
};

EpisodesAddToListMenu.defaultProps = {
  mobile: false,
  showShare: false,
  showEllipsisItems: null,
};

export default memo(EpisodesAddToListMenu);
