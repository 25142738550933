import { faCircleNotch } from "@fortawesome/free-solid-svg-icons/faCircleNotch";
import { faTrash } from "@fortawesome/free-solid-svg-icons/faTrash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Map } from "immutable";
import { useCallback, useState } from "react";
import { removeItemFromListPromise } from "routines/userlist";

import BasicTooltip from "components/Common/Tooltip/BasicTooltip";

import sendGAEvent from "utils/sendGAEvent";

import useIsListOwner from "hooks/useIsListOwner";
import useRoutinePromises from "hooks/useRoutinePromises";
import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";

const baseStyles = {
  addToListIcon: {
    width: "1rem",
    height: "1rem",
    border: "none",
    margin: 0,
    marginLeft: "auto",
    padding: 0,

    ":hover": {
      cursor: "pointer",
    },
  },
  full: {
    width: "max-content",
    height: "2rem",
    display: "flex",
    gap: "0.5rem",
    alignItems: "center",
    ...gStyles.avalonBold,
    fontSize: "0.813rem",
    padding: "0 0.6rem",
    borderRadius: 25,
    border: `1px solid ${colours.borderGrey}`,
    color: colours.red,

    ":hover": {
      cursor: "pointer",
      color: colours.negative,
      transition: "ease-out 0.2s",
      transform: "translateY(-2px)",
    },
  },
};

export default function RemoveFromListButton(props) {
  const { listId, entityType, entityId } = props;

  const isOwner = useIsListOwner(listId);

  const { styles, css } = useStyles(baseStyles, props);
  const [deletingList, setDeletingList] = useState(false);

  const { removeItemFromList } = useRoutinePromises({
    removeItemFromList: removeItemFromListPromise,
  });

  const item = Map({
    entity_id: entityId,
    entity_type: entityType,
  });

  const handleRemoveFromList = useCallback(() => {
    sendGAEvent({
      action: "cardRemoveItemActionClick",
      context: "UserlistCardView",
      entity_name: entityType,
      entity_id: entityId,
    });
    setDeletingList(true);

    removeItemFromList({
      list_id: listId,
      item,
    })
      .then(() => {
        setDeletingList(false);
      })
      .catch((e) => {
        setDeletingList(false);
      });
  }, [entityType, entityId, removeItemFromList, listId, item]);

  if (!isOwner) {
    return null;
  }

  return (
    <BasicTooltip placement="bottom" renderTooltip={() => "Remove from List"}>
      {(tooltipProps) => (
        <button
          {...tooltipProps}
          className={css(styles.addToListIcon, styles.full)}
          onClick={handleRemoveFromList}
          disabled={deletingList}
        >
          {deletingList ? (
            <FontAwesomeIcon icon={faCircleNotch} dataid="circle" />
          ) : (
            <FontAwesomeIcon icon={faTrash} dataid="delete" />
          )}
        </button>
      )}
    </BasicTooltip>
  );
}
