import { css } from "aphrodite";
import PropTypes from "prop-types";

import { useStyles } from "hooks/useStyles";

import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  subtitleWrapper: {
    ...gStyles.fontMedium,
    lineHeight: "1.5",
    color: "#fff",
    textAlign: "center",
    padding: "0 1rem",

    fontSize: "1rem",
    margin: "0 auto -0.75rem",
    width: "100%",
    maxWidth: "50%",

    [ScreenSizes.xlAndBelow]: {
      maxWidth: "70%",
    },
    [ScreenSizes.lgAndBelow]: {
      maxWidth: "85%",
    },
    [ScreenSizes.mdAndBelow]: {
      maxWidth: "100%",
    },
  },
};

const HeaderSubtitle = (props) => {
  const { children } = props;
  const { styles } = useStyles(baseStyles, props);

  return <div className={css(styles.subtitleWrapper)}>{children}</div>;
};

HeaderSubtitle.propTypes = {
  children: PropTypes.node,
};

HeaderSubtitle.defaultProps = {
  children: null,
};

export default HeaderSubtitle;
