import PropTypes from "prop-types";

import UserlistFeatureItem from "./UserlistFeatureItem";

import { selectSpecificList } from "selectors/userlist";

import useListOwner from "hooks/useListOwner";
import useReduxState from "hooks/useReduxState";

const UserlistFeatureItemContainer = (props) => {
  const { listId, hideUser, showInfoIcons, compact } = props;
  const list = useReduxState(
    (state) => selectSpecificList(state, listId),
    [listId]
  );

  const owner = useListOwner(list);

  if (!list) {
    return null;
  }

  return (
    <UserlistFeatureItem
      hideUser={hideUser}
      showInfoIcons={showInfoIcons}
      compact={compact}
      owner={owner}
      list={list}
    />
  );
};

UserlistFeatureItemContainer.propTypes = {
  listId: PropTypes.number,
  hideUser: PropTypes.bool,
  showInfoIcons: PropTypes.bool,
  compact: PropTypes.bool,
};

UserlistFeatureItemContainer.defaultProps = {
  hideUser: false,
  showInfoIcons: true,
  compact: false,
};

export default UserlistFeatureItemContainer;
