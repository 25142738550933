import { episodeSchema, podcastSchema } from "api/schema/creator_podcast";
import { schema } from "normalizr";

import { userSchema } from "./user";

const listEntity = new schema.Union(
  { podcast: podcastSchema, episode: episodeSchema },
  (entity, parent) => parent.entity_type
);

const userlistSchema = new schema.Entity("userlists", {
  idAttribute: "identifier",
  items: [{ entity: listEntity }],
  owners: new schema.Array(userSchema),
});

export { userlistSchema };
