import { faPencil } from "@fortawesome/pro-regular-svg-icons/faPencil";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback } from "react";
import { Link } from "react-router-dom";

import BasicTooltip from "components/Common/Tooltip/BasicTooltip";

import sendGAEvent from "utils/sendGAEvent";

import useIsListOwner from "hooks/useIsListOwner";
import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";

const baseStyles = {
  button: {
    height: "2rem",
    width: "2rem",
    display: "grid",
    placeItems: "center",
    border: `1px solid ${colours.borderGrey}`,
    borderRadius: "25px",
    fontSize: "0.813rem",

    ":hover": {
      cursor: "pointer",
      backgroundColor: "#f7f7f7",
    },
  },
};

function ListEditCardAction(props) {
  const { styles, css } = useStyles(baseStyles, props);
  const { listUrl, listId } = props;

  const isOwner = useIsListOwner(listId);

  const handleClick = useCallback(
    () =>
      sendGAEvent({
        action: "editUserlistButtonClicked",
        creatorId: listId,
      }),
    [listId]
  );

  if (!isOwner) {
    return null;
  }

  return (
    <BasicTooltip renderTooltip={() => "Edit your list"}>
      {(tooltipProps) => (
        <Link
          {...tooltipProps}
          to={listUrl}
          className={css(styles.button)}
          onClick={handleClick}
        >
          <FontAwesomeIcon icon={faPencil} dataid="edit" />
        </Link>
      )}
    </BasicTooltip>
  );
}

export default ListEditCardAction;
