import pluralize from "pluralize";
import React, { useCallback, useMemo } from "react";
import { Link } from "react-router-dom";

import ShowMoreContainer from "components/Common/table/commons/ShowMoreContainer";
import HighLightText from "components/Highlight/HighLightText";
import PodcastProfileHoverPopover from "components/Profiles/PodcastProfileHoverPopover";

import ListCardCurator from "./ListCardCurator";

import getEpisodeUrl from "utils/entity/getEpisodeUrl";
import getPodcastUrl from "utils/entity/getPodcastUrl";
import { capitalize } from "utils/misc";
import sendGAEvent from "utils/sendGAEvent";
import commaList from "utils/text/commaList";
import { truncateString } from "utils/truncate";

import useIsListOwner from "hooks/useIsListOwner";
import { useStyles } from "hooks/useStyles";
import useWindowSize from "hooks/useWindowSize";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  container: {
    padding: "1rem 1.5rem",
    borderLeft: `4px solid ${colours.borderGrey}`,
    display: "grid",
    gridTemplateRows: "max-content max-content",
    gridGap: "0.5rem",
    marginBottom: "1rem",
    background: "var(--color-neutral-d10)",
    borderTopRightRadius: "0.25rem",
    borderBottomRightRadius: "0.25rem",
  },
  ownerContainer: {
    borderLeft: `4px solid ${colours.secondary}`,
  },
  descriptionText: {
    display: "flex",
    ...gStyles.fontRegular,
    fontSize: 14,
    flexWrap: "wrap",
    textWrap: "nowrap",
    alignItems: "center",
    fontStyle: "italic",
  },
  extra: {
    padding: 0,
    margin: "0 0 0 3px",
    display: "inline",

    [ScreenSizes.xlAndAbove]: {
      display: "bloxk",
    },
  },
  text: {
    ...gStyles.fontRegular,
    fontSize: 14,
    lineHeight: "16px",
    display: "block",
    color: colours.black,
    fontStyle: "italic",
    margin: 0,
    width: "max-content",
    whiteSpace: "noWrap",
    marginLeft: 3,

    cursor: "pointer",

    ":hover": {
      color: colours.primary,
    },
    ":last-child": {
      maxWidth: "100%",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
};

const showMoreOverride = {
  details: {
    fontStyle: "italic",
  },
};

export default function ListCardDescription(props) {
  const { list, principalSearchTerm, secondarySearchTerm } = props;

  const { styles, css } = useStyles(baseStyles, props);

  const { isWindowSizeOrLess } = useWindowSize();
  const mobile = isWindowSizeOrLess("medium");

  const listDescription = list?.get("description");
  const previewEntities = list?.get("preview_entities");
  const listId = list?.get("id");
  const listType = list?.get("entity_type");
  const itemCount = list?.get("item_count");

  const isOwner = useIsListOwner(listId);

  const maxItems = listType === "episode" || mobile ? 1 : 2;
  const remainingItems = itemCount - maxItems;

  const handleClick = useCallback(
    (entity) => {
      sendGAEvent({
        action: "UserlistFeaturedEntityClickedClick",
        entityTitle: entity?.get("title"),
        entity_id: entity?.get("id"),
        entityType: listType,
        context: "UserlistCard",
      });
    },
    [listType]
  );

  const fallbackDescription = useMemo(() => {
    const titleList =
      previewEntities &&
      commaList(
        previewEntities.slice(0, maxItems),
        (entity) => {
          if (listType === "episode") {
            const episodeUrl = getEpisodeUrl(entity);
            return (
              <Link
                key={entity?.get("id")}
                to={episodeUrl}
                onClick={() => handleClick(entity)}
              >
                <h4 className={css(styles.text)}>
                  {`${truncateString(entity.get("title"), 30)}`}
                </h4>
              </Link>
            );
          }
          if (listType === "podcast") {
            const podcastUrl = getPodcastUrl(entity);
            return (
              <Link
                key={entity?.get("id")}
                to={podcastUrl}
                onClick={() => handleClick(entity)}
              >
                <PodcastProfileHoverPopover entity_id={entity.get("id")} inline>
                  <h4 className={css(styles.text)}>
                    {`${truncateString(entity.get("title"), 30)}`}
                  </h4>
                </PodcastProfileHoverPopover>
              </Link>
            );
          }

          return null;
        },
        { skipConjunction: itemCount > maxItems }
      );

    const additional =
      list?.get("item_count") > 3 ? (
        <p className={css(styles.extra)}>{`and ${remainingItems} ${pluralize(
          "other",
          remainingItems
        )}`}</p>
      ) : (
        ""
      );

    return (
      <div className={css(styles.descriptionText)}>
        {capitalize(listType)} list featuring {titleList}
        {additional}
      </div>
    );
  }, [
    previewEntities,
    maxItems,
    itemCount,
    list,
    css,
    styles.extra,
    styles.descriptionText,
    styles.text,
    remainingItems,
    listType,
    handleClick,
  ]);

  if (!list) {
    return null;
  }

  const visibleContentNode = listDescription && (
    <HighLightText
      textToHighlight={listDescription?.substr(0, 320)}
      principalSearchTerm={principalSearchTerm}
      secondarySearchTerm={secondarySearchTerm}
    />
  );

  const hiddenText = listDescription && listDescription?.substr(320);
  const hiddenContent = hiddenText && (
    <HighLightText
      textToHighlight={hiddenText}
      principalSearchTerm={principalSearchTerm}
      secondarySearchTerm={secondarySearchTerm}
    />
  );

  return (
    <div className={css(styles.container, isOwner && styles.ownerContainer)}>
      <ListCardCurator listId={listId} />
      {listDescription ? (
        <div className={css(styles.description)}>
          <ShowMoreContainer
            entity={list}
            entityType="List"
            visibleContent={<span>"{visibleContentNode}"</span>}
            hiddenContent={hiddenContent}
            styles={showMoreOverride}
          />
        </div>
      ) : (
        fallbackDescription
      )}
    </div>
  );
}
