import React, { useCallback } from "react";
import { Link } from "react-router-dom";

import BasicTooltip from "components/Common/Tooltip/BasicTooltip";

import getUserListUrl from "utils/entity/getUserListUrl";
import sendGAEvent from "utils/sendGAEvent";
import commaList from "utils/text/commaList";

import { useStyles } from "hooks/useStyles";
import useWindowSize from "hooks/useWindowSize";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import { createBreakpoint } from "styles/ScreenSizes";

const baseStyles = {
  categories: {
    display: "flex",
    alignItems: "Center",
    gap: "0.3rem",
  },
  text: {
    ...gStyles.avalonBold,
    fontSize: ".813rem",
    lineHeight: "16px",

    display: "block",

    color: colours.darkGrey,
    background: colours.neutral,

    padding: "0 8px",

    margin: 0,

    borderRadius: 100,

    width: "fit-content",
    "-webkit-line-clamp": "1",
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    maxWidth: 90,

    ":hover": {
      cursor: "pointer",
    },
  },
  extra: {
    maxWidth: 35,
  },
  bottomRowContainer: {
    display: "grid",
    gridTemplateColumns: "90px 1fr",
    gridGap: "1rem",
    alignItems: "center",
    maxWidth: "100%",

    [createBreakpoint({ min: 1115 })]: {
      maxWidth: 370,
      display: "grid",
    },
  },
  bottomRowLabel: {
    ...gStyles.avalonBold,
    fontSize: "0.813rem",
    margin: 0,
    display: "flex",
    gap: "0.5rem",
  },
};

function ListCardCategories(props) {
  const { styles, css } = useStyles(baseStyles, props);
  const { list } = props;

  const { isWindowSizeOrLess } = useWindowSize();
  const isMediumOrLess = isWindowSizeOrLess("large");

  const listUrl = getUserListUrl(list);

  const onCategoryClick = (category) => {
    sendGAEvent({
      action: "UserlistCardCategoryPillClick",
      listTitle: list?.get("title"),
      list_id: list?.get("id"),
      categoryTitle: category,
      context: "UserlistCard",
    });
  };

  const onMoreCategoryClick = () => {
    sendGAEvent({
      action: "UserlistCardCategoryExtrasClick",
      listTitle: list?.get("title"),
      list_id: list?.get("id"),
      context: "UserlistCard",
    });
  };

  const max = isMediumOrLess ? 2 : 3;

  const categories = list?.get("categories");
  const categoriesToShow = categories?.slice(0, max);

  const hasMore = categories?.size - max > 0;

  const renderTooltip = useCallback(() => {
    const list = categories?.map((category, index) => {
      if (index > categoriesToShow?.size - 1) {
        return category?.get("text");
      }
      return null;
    });

    return commaList(list, (cat) => <span>{cat}</span>);
  }, [categories, categoriesToShow?.size]);

  if (!categories || categories?.size === 0) {
    return null;
  }

  return (
    <div className={css(styles.bottomRowContainer)}>
      <h3 className={css(styles.bottomRowLabel)}>Categories</h3>
      <section className={css(styles.categories)}>
        {categoriesToShow.map((category) => {
          const categoryText = category?.get("text");

          return (
            <BasicTooltip renderTooltip={() => categoryText}>
              {(tooltipProps) => (
                <Link
                  {...tooltipProps}
                  key={category}
                  to={`/categories/${category?.get("slug")}/podcasts`}
                  className={css(styles.text)}
                  onClick={() => onCategoryClick(categoryText)}
                >
                  {categoryText}
                </Link>
              )}
            </BasicTooltip>
          );
        })}
        {hasMore && (
          <BasicTooltip
            renderTooltip={renderTooltip}
            zIndex={99999}
            forceOpen={true}
          >
            {(tooltipProps) => (
              <Link
                key={"extra"}
                to={listUrl}
                className={css(styles.text, styles.extra)}
                {...tooltipProps}
                onClick={onMoreCategoryClick}
              >
                +{categories?.size - max}
              </Link>
            )}
          </BasicTooltip>
        )}
      </section>
    </div>
  );
}

export default ListCardCategories;
