import { faHeart } from "@fortawesome/free-solid-svg-icons/faHeart";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "aphrodite";
import { format } from "date-fns/format";
import pluralize from "pluralize";

import BasicTooltip from "components/Common/Tooltip/BasicTooltip";

import { capitalize } from "utils/misc";

import { useStyles } from "hooks/useStyles";
import useUserList from "hooks/useUserList";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";

const baseStyles = {
  container: {
    display: "flex",
    gap: 5,
  },
  bubble: {
    ...gStyles.avalonBold,
    fontSize: 11,
    lineHeight: "16px",

    display: "flex",
    gap: "0.25rem",
    alignItems: "center",

    color: colours.darkGrey,
    background: colours.neutral,

    padding: "0 8px",

    margin: 0,

    borderRadius: 100,

    width: "fit-content",
    "-webkit-line-clamp": "1",
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",

    ":hover": {
      cursor: "pointer",
    },
  },
  noBackground: {
    background: "none",
  },
  icon: {
    fontSize: 10,
    marginTop: 2,
    color: colours.red,
  },
};

export default function ListCardStats(props) {
  const { styles } = useStyles(baseStyles, props);

  const { listId } = props;

  const list = useUserList(listId);

  const entityCount = list?.get("item_count");
  const likesCount = list?.get("like_count");
  const listType = list?.get("entity_type");
  const updatedAt = list?.get("updated_at");
  const formattedDate = updatedAt && format(new Date(updatedAt), "MMM d, yyyy");

  return (
    <div className={css(styles.container)}>
      <BasicTooltip
        renderTooltip={() =>
          `${entityCount} ${pluralize(capitalize(listType, entityCount))}`
        }
      >
        {(tooltipProps) => (
          <div {...tooltipProps} className={css(styles.bubble)}>
            {entityCount} {pluralize(capitalize(listType, entityCount))}
          </div>
        )}
      </BasicTooltip>
      <BasicTooltip
        renderTooltip={() => `${likesCount} ${pluralize("Like", likesCount)}`}
      >
        {(tooltipProps) => (
          <div {...tooltipProps} className={css(styles.bubble)}>
            <span className={css(styles.icon)}>
              <FontAwesomeIcon icon={faHeart} dataid="heart" />
            </span>
            {likesCount}
          </div>
        )}
      </BasicTooltip>
      <BasicTooltip renderTooltip={() => `Last updated on ${formattedDate}`}>
        {(tooltipProps) => (
          <div
            {...tooltipProps}
            className={css(styles.bubble, styles.noBackground)}
          >
            {formattedDate}
          </div>
        )}
      </BasicTooltip>
    </div>
  );
}
