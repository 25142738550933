import { creatorSchema, podcastSchema } from "api/schema/creator_podcast";
import { Map } from "immutable";
import { normalize, schema } from "normalizr";
import qs from "qs";
import { CANCEL } from "redux-saga";

import { userlistSchema } from "../schema/userlist";
import client from "./client";
import {
  getAgeData,
  getCategoryData,
  getDemographicsData,
  getIncomeData,
  getMonthlyListenersData,
  getParentalData,
  getPowerScoreData,
  getReachEstimateData,
  getStatusData,
  getTopCountriesData,
  getTopInterestsData,
} from "./networkTransformer";

export function loadSpecificList({ list_id }) {
  const request = client.get(`/userlists/${list_id}`).then((response) => {
    const { list, additional_entities } = response.data;
    const filteredList = {
      ...list,
      items: list.items.filter(
        (i) => i.entity_type === "heading" || (i.entity && i.entity.id)
      ),
    };
    const normalized = normalize(
      { list: filteredList, ...additional_entities },
      {
        list: userlistSchema,
        creators: [creatorSchema],
        podcasts: [podcastSchema],
      }
    );
    return {
      list_id: list.id,
      entities: normalized.entities,
    };
  });

  return request;
}

export function updateListOrder({ list_id, ids }) {
  return client
    .post(`/my/userlists/${list_id}/reorder`, { ids })
    .then((response) => response.data);
}

export function addItemToList({ list_id, item }) {
  return client
    .put(`/my/userlists/${list_id}/items`, { item })
    .then((response) => {
      const normalized = normalize(response.data, userlistSchema);
      return normalized;
    });
}

export function addItemsToList({ listId, items }) {
  return client
    .put(`/my/userlists/${listId}/items`, { items })
    .then((response) => {
      const normalized = normalize(response.data, userlistSchema);
      return normalized;
    });
}

export function createNewList({
  list,
  item = Map(),
  items,
  details,
  recaptchaToken,
}) {
  const params = { ...list, details, ...item.toJS(), recaptchaToken };
  if (items) {
    params.items = items;
  }

  return client.post("/my/userlists/", params).then((response) => {
    if (!item.has("entity_id") && !items) {
      // just put an empty array of items in here for now so it doesn't look like an unloaded list
      response.data.items = [];
    }

    const normalized = normalize(response.data, userlistSchema);
    return normalized;
  });
}

export function removeItemFromList({ list_id, item }) {
  return client
    .delete(`/my/userlists/${list_id}/items/${item.get("id")}`)
    .then((response) => {
      const normalized = normalize(response.data, userlistSchema);
      return normalized;
    });
}

export function updateSectionHeading({ list_id, item }) {
  return client
    .put(`/my/userlists/${list_id}/items/${item.get("id")}`, item.toJS())
    .then((response) => response.data);
}

export function removeItemFromListByEntityID({ list_id, item }) {
  return client
    .delete(`/my/userlists/${list_id}/items/entity/${item.get("entity_id")}`)
    .then((response) => {
      const normalized = normalize(response.data, userlistSchema);
      return normalized;
    });
}

export function setListPrivacy({ list_id, privacy }) {
  return client
    .put(`/my/userlists/${list_id}/privacy`, { privacy })
    .then((response) => {
      const normalized = normalize(response.data, userlistSchema);
      return normalized;
    });
}

export function regenerateLinkOnlyID({ list_id }) {
  return client
    .put(`/my/userlists/${list_id}/regenerate_id`)
    .then((response) => {
      const normalized = normalize(response.data, userlistSchema);
      return normalized;
    });
}

export function getListSummaries({ entity_type }) {
  return client
    .get(`/my/userlists?${qs.stringify({ entity_type })}`)
    .then((response) => response.data);
}

export function deleteList({ list_id }) {
  return client
    .delete(`/my/userlists/${list_id}`)
    .then((response) => response.data);
}

export function loadUserListFilterList({
  sort_order,
  sort_direction,
  offset,
  count,
  filters,
  cancelToken,
  options,
  omit_results,
  total_hits,
}) {
  const request = client
    .post(
      "/list/userlist",
      {
        start: offset,
        count,
        sort_order,
        sort_direction,
        filters,
        options,
        omit_results,
        total_hits,
      },
      { cancelToken: cancelToken.token }
    )
    .then((response) => {
      const { entities, ...data } = response.data;

      const normalised = normalize(entities, new schema.Array(userlistSchema));

      return { ...normalised, meta: { ...data } };
    });

  request[CANCEL] = () => cancelToken.cancel();

  return request;
}

export function saveNoteForItem({ list_id, item_id, note: passedNote }) {
  let note = passedNote;

  if (Map.isMap(note)) {
    note = note.toJS();
  }
  return client
    .post(`/my/userlists/${list_id}/items/${item_id}/notes`, {
      note,
    })
    .then((response) => response.data);
}

export function deleteNoteForItem({ list_id, item_id, note_id }) {
  return client
    .delete(`/my/userlists/${list_id}/items/${item_id}/notes/${note_id}`)
    .then((response) => response.data);
}

export function saveListDetails({ list_id, details, recaptchaToken }) {
  return client
    .put(`/my/userlists/${list_id}`, {
      details,
      recaptchaToken,
    })
    .then((response) => response.data);
}

export function loadProfileUserLists({ entity_type }) {
  return client
    .get(`/my/userlists?${qs.stringify({ with_items: false, entity_type })}`)
    .then((response) => {
      const normalized = normalize(
        response.data,
        new schema.Array(userlistSchema)
      );
      return normalized;
    });
}

export function regenerateShareImage({ list_id }) {
  return client
    .put(`/my/userlists/${list_id}/share_image`)
    .then((response) => response.data);
}

export function loadContactCount({ list_id }) {
  return client
    .get(`/userlists/${list_id}/contact_counts`)
    .then((response) => response.data);
}

export function loadUserlistInsights(props) {
  const { userlistTitle, listId, filters: passedFilters, hasPro } = props;

  const filters = {
    userlist: [userlistTitle],
    in_list: listId,
    ...passedFilters,
  };

  const aggregationsNonPro = {
    category: {
      type: "category",
    },
    status: { type: "status" },
  };

  const aggregationsPro = {
    income_tier: { type: "income_tier" },
    age: { type: "age" },
    top_interests: { type: "top_interests" },
    top_countries: { type: "top_countries" },
    power_score: { type: "power_score" },
    power_score_median: { type: "power_score_median" },
    gender: { type: "gender_bias_identifier" },
    parental_skew: { type: "parental_skew" },
    category: {
      type: "category",
    },
    status: { type: "status" },
    reach_estimate_from: {
      type: "reach_estimate_from",
    },
    reach_estimate_to: {
      type: "reach_estimate_to",
    },
    histogram: {
      type: "reach_histogram",
    },
  };

  const request = client
    .post("/list/podcast", {
      omit_results: true,
      filters,
      aggregations: hasPro ? aggregationsPro : aggregationsNonPro,
    })
    .then((response) => {
      return {
        monthlyListeners: getMonthlyListenersData(
          response?.data?.aggregations?.histogram?.buckets
        ),
        reachEstimate: getReachEstimateData(
          response?.data?.aggregations?.reach_estimate_from?.buckets,
          response?.data?.aggregations?.reach_estimate_to?.buckets
        ),
        income: getIncomeData(
          response?.data?.aggregations?.income_tier?.buckets
        ),
        category: getCategoryData(
          response?.data?.aggregations?.category?.buckets
        ),
        parental: getParentalData(
          response?.data?.aggregations?.parental_skew?.buckets
        ),
        powerScore: getPowerScoreData(
          response?.data?.aggregations?.power_score?.buckets,
          response?.data?.aggregations?.power_score_median?.buckets
        ),
        age: getAgeData(response?.data?.aggregations?.age?.buckets),
        topInterests: getTopInterestsData(
          response?.data?.aggregations?.top_interests?.buckets
        ),
        gender: getDemographicsData(
          response?.data?.aggregations?.gender?.buckets
        ),
        statuses: getStatusData(response?.data?.aggregations?.status?.buckets),
        topCountries: getTopCountriesData(
          response?.data?.aggregations?.top_countries
        ),
      };
    });

  return request;
}
