import React, { useCallback } from "react";
import { Link } from "react-router-dom";

import EntityImage from "components/Entities/EntityImage";

import getUserUrl from "utils/entity/getUserUrl";
import sendGAEvent from "utils/sendGAEvent";

import useIsListOwner from "hooks/useIsListOwner";
import useListOwner from "hooks/useListOwner";
import { useStyles } from "hooks/useStyles";
import useUserList from "hooks/useUserList";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";

const baseStyles = {
  topRow: {
    display: "grid",
    alignItems: "center",
    gridTemplateColumns: "max-content max-content",
    gridGap: "0.5rem",
  },
  curator: {
    margin: 0,
    ...gStyles.avalonBold,
    fontSize: 11,
    color: "var(--color-neutral-d5)",
    maxWidth: "100%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  owner: {
    color: colours.secondary,
  },
};

const imagestyles = {
  entityImage: {
    height: 20,
    width: 20,
    minWidth: 20,
  },
};

export default function ListCardCurator(props) {
  const { styles, css } = useStyles(baseStyles, props);
  const { listId } = props;

  const list = useUserList(listId);
  const owner = useListOwner(list);
  const isOwner = useIsListOwner(list?.get("id"));
  const userProfileUrl = getUserUrl(owner);
  const ownerName = owner?.get("display_name") || owner?.get("username");

  const handleClick = useCallback(() => {
    sendGAEvent({
      action: "userlistCardCuratorClicked",
      listId: list?.get("id"),
      list_name: list?.get("title"),
      context: "Userlist Card",
    });
  }, [list]);

  return (
    <div className={css(styles.topRow)}>
      <EntityImage
        entity_type="user"
        entity={owner}
        size={20}
        profileImage
        styles={imagestyles}
      />
      <Link
        to={userProfileUrl}
        className={css(styles.curator, isOwner && styles.owner)}
        onClick={handleClick}
      >
        {isOwner ? "This is your list" : `Curated by ${ownerName}`}
      </Link>
    </div>
  );
}
