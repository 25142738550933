import { css } from "aphrodite";
import PropTypes from "prop-types";

import { useStyles } from "hooks/useStyles";

import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  headerWrapper: {
    ...gStyles.gradientBackground,
    ...gStyles.headerWrapper,
    minHeight: "170px",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",

    [ScreenSizes.lgAndAbove]: {
      ...gStyles.headerWrapper[ScreenSizes.lgAndAbove],
      minHeight: "296px",
    },
  },
};

const HeaderBlock = (props) => {
  const { children } = props;
  const { styles } = useStyles(baseStyles, props);

  return <div className={css(styles.headerWrapper)}>{children}</div>;
};

HeaderBlock.propTypes = {
  children: PropTypes.node,
};

HeaderBlock.defaultProps = {
  children: null,
};

export default HeaderBlock;
