import { faClock } from "@fortawesome/free-regular-svg-icons/faClock";
import { faHeart as faHeartRegular } from "@fortawesome/free-regular-svg-icons/faHeart";
import { faFireAlt } from "@fortawesome/free-solid-svg-icons/faFireAlt";
import { css } from "aphrodite";
import CarouselMaybe from "nuka-carousel";
import PropTypes from "prop-types";
import { Fragment, useCallback, useMemo } from "react";
import Helmet from "react-helmet-async";

import StandardButton from "components/Buttons/StandardButton";
import ContentContainer from "components/Common/Layout/ContentContainer";
import HeaderBlock from "components/Common/Layout/HeaderBlock";
import HeaderSubtitle from "components/Common/Layout/HeaderSubtitle";
import PageTitle from "components/Common/Layout/PageTitle";
import CTABox from "components/CTAs/CTABox";
import ListDesktopCardContainer from "components/Entities/Cards/ListCards/ListDesktopCardContainer";
import UserlistFeatureItemContainer from "components/Entities/Items/UserlistFeatureItemContainer";
import Title from "components/Entities/Page/TitleAsync";
import EntityNavigateSearch from "components/Search/EntityNavigateSearch";
import FilteredListContainer from "containers/FilteredListContainer";

import tokens from "../../styles/tokens/tokens";

import modalActions from "actions/modals";
import { getBaseUrl } from "constants/base";
import { generateMetaArray } from "utils/meta";

import useActionCreators from "hooks/useActionCreators";
import { useStyles } from "hooks/useStyles";
import useWindowSize from "hooks/useWindowSize";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import PageStyles from "styles/PageStyles";
import ScreenSizes from "styles/ScreenSizes";

const Carousel = CarouselMaybe.default || CarouselMaybe;

const baseStyles = {
  ...PageStyles,
  headerItemWrapper: {
    marginBottom: "0.5rem",
    width: "100%",

    [ScreenSizes.lgAndAbove]: {
      marginBottom: "1.5rem",
    },
  },
  searchFieldWrapper: {
    width: "100%",
  },
  contentContainerWrapper: {
    backgroundColor: tokens.color.background.default,
    padding: "2rem 1.5rem",

    [ScreenSizes.xlAndAbove]: {
      padding: "3rem 0",
    },
  },
  contentWrapper: {
    display: "flex",
    flexDirection: "row",
  },
  listsColumn: {
    // Copied from Page, might be a better way to make this consistent across more pages
    display: "flex",
    flex: 2,
    flexDirection: "column",
    maxWidth: "100%",
    overflow: "hidden",
    paddingRight: "4%",
    paddingLeft: "2rem",
    marginLeft: "-2rem",

    [ScreenSizes.xlAndAbove]: {
      paddingRight: "8%",
    },
  },
  mostLikedSection: {
    display: "flex",
    flex: 1,
    flexDirection: "column",

    [ScreenSizes.lgAndAbove]: {
      maxWidth: 250,
    },
    [ScreenSizes.xlAndAbove]: {
      maxWidth: 300,
    },
  },
  desktopMostLikedSection: {
    display: "flex",
    flex: 1,
    flexDirection: "column",

    [ScreenSizes.mdAndBelow]: {
      display: "none",
    },
    [ScreenSizes.lgAndAbove]: {
      maxWidth: 250,
    },
    [ScreenSizes.xlAndAbove]: {
      maxWidth: 300,
    },
  },
  sidebarCTABox: {
    marginBottom: "3rem",
  },
  ctaText: {
    display: "block",
    marginBottom: "1rem",
  },

  viewAllAside: {
    [ScreenSizes.lgOnly]: {
      marginLeft: 0,
      marginTop: "0.25rem",
    },
  },
  newListButtonWrapper: {
    width: "100%",
    padding: 0,
    margin: "0 auto 2rem",
    maxWidth: "20rem",

    [ScreenSizes.smAndAbove]: {
      padding: "0 10%",
    },
    [ScreenSizes.lgAndAbove]: {
      width: "100%", // '176px',
      marginBottom: "2.6em", // 0,
      padding: 0, // 'inherit'
    },
  },
  horizontalCardsWrapper: {
    display: "flex",
    justifyContent: "space-between",
    gap: "1rem",
    overflow: "auto",
  },
  carouselListItem: {
    // need this to make sure the card drop shadow doesn't get cut off by the slider
    marginBottom: "10px",
  },
  subtitleUpdatedAt: {
    ...gStyles.fontSemiBold,
    color: "var(--color-neutral-d6)",
  },
  likedListWrapper: {
    marginBottom: "1.5rem",
  },
  carouselWrapper: {
    marginBottom: 12,
  },
  searchWrapper: {
    ...gStyles.headerSearchWrapper,
  },
  moreLink: {
    ...gStyles.fontRegular,
    fontSize: ".8em",
    color: colours.bodyText,
    marginLeft: "20px",
    whiteSpace: "nowrap",
  },
  trendingSection: {
    [ScreenSizes.lgAndAbove]: {
      marginBottom: "65px",
    },
  },
  horizontalCardWrapper: {
    marginBottom: "1rem",

    [ScreenSizes.lgAndAbove]: {
      display: "block",
      width: "100%",
    },
  },
  listDesktopCardContainer: {
    marginBottom: "2rem",
  },
};

const searchStyles = {
  ...gStyles.headerSearchStyles,
};

const titleStyles = {
  titleContainer: {
    marginTop: 0,
    paddingTop: 0,

    [ScreenSizes.lgAndAbove]: {
      marginTop: 0,
      paddingTop: 0,
    },
  },
};

const asideTitleStyles = {
  ...titleStyles,
  titleContainer: {
    ...titleStyles.titleContainer,
    justifyContent: "space-between",
    marginRight: 0,

    [ScreenSizes.lgAndAbove]: {
      ...titleStyles.titleContainer[ScreenSizes.lgAndAbove],
      justifyContent: "flex-start",
      marginRight: 0,
    },
  },
};

const searchFieldStyles = {
  search: {
    [ScreenSizes.lgAndAbove]: {
      background: colours.white,
      zIndex: 99,
    },
  },
  icon: {
    marginLeft: "1rem",
    [ScreenSizes.lgAndAbove]: {
      color: colours.bodyText,
    },
  },
  input: {
    color: colours.bodyText,
  },
  iconFocussedWithText: {
    transition: "ease-out 0.2s",
    [ScreenSizes.lgAndAbove]: {
      transition: "ease-out 0.2s",
      color: colours.bodyText,
    },
  },
};

const autoCompleteStyles = {
  autocompleteOuter: {
    zIndex: 9,
  },
};

const fullWidthStyles = {
  contentContainer: {
    padding: "0 2rem",
    maxWidth: "100%",
    margin: 0,
    [ScreenSizes.mdOnly]: {
      padding: "0 1.5rem",
    },
    [ScreenSizes.lgOnly]: {
      padding: "0 2.2rem",
    },
    [ScreenSizes.xlAndAbove]: {
      padding: "0 3rem",
    },
  },
};

const analyticsVariables = {
  action: "userlistSearch",
  context: "userlistsIndex",
  componentContext: "UserListsHub",
};

const ctaAnalyticsVariables = {
  action: "createListModalOpened",
  context: "pageCTAClicked",
  componentContext: "UserListsHub",
};

const SEARCH_TYPES = ["userlist"];
const nullFunction = () => null;

const UserListsHub = (props) => {
  const { toggleListLike } = props;
  const { styles } = useStyles(baseStyles, props);

  const { showModal } = useActionCreators(modalActions);

  const { isWindowSizeOrLess, isWindowSizeOrMore, getWindowWidth } =
    useWindowSize();
  const isMediumOrLess = isWindowSizeOrLess("medium");

  const renderConnectedListCardNarrow = (id, index, compact = false) => (
    <UserlistFeatureItemContainer
      key={id}
      listId={id}
      toggleLike={toggleListLike}
      compact={compact}
      renderOnMount
    />
  );

  const renderCarousel = (ids, renderAlt) => {
    if (!isMediumOrLess) {
      return renderAlt(ids);
    }
    if (!ids) {
      return null;
    }

    return (
      <div className={css(styles.carouselWrapper)}>
        <Carousel
          cellSpacing={24}
          slideWidth={`${Math.min(getWindowWidth(), 300) * 0.85}px`}
          renderCenterLeftControls={nullFunction}
          renderCenterRightControls={nullFunction}
          renderBottomCenterControls={nullFunction}
          swipeDeadZone={20}
        >
          {ids.map((id, index) => (
            <div className={css(styles.carouselListItem)} key={id}>
              {renderConnectedListCardNarrow(id, index)}
            </div>
          ))}
        </Carousel>
      </div>
    );
  };

  const renderFilteredList = (filteredListProps) => {
    const {
      renderAltCarousel,
      viewMoreLink,
      title,
      listKey,
      aside = false,
    } = filteredListProps;

    const renderTitle = () => (
      <Title
        title={title}
        link={viewMoreLink}
        viewAllText="View All"
        viewAllTitle={`View All ${title}`}
        TitleComponent="h2"
        styles={aside ? asideTitleStyles : titleStyles}
      />
    );

    return (
      <Fragment>
        {title && renderTitle()}
        <FilteredListContainer listKey={listKey}>
          {({ ids }) => renderCarousel(ids, renderAltCarousel)}
        </FilteredListContainer>
      </Fragment>
    );
  };

  const handleCreateNewListClick = useCallback(
    () => showModal("createList"),
    [showModal]
  );

  const renderNewListButton = () => (
    <div className={css(styles.newListButtonWrapper)}>
      <StandardButton
        variation="pink"
        label="Create Your Own List"
        onClick={handleCreateNewListClick}
        dataId="create_new_list_button"
        withPadding
      />
    </div>
  );

  const renderTrendingCardsFullWidth = (ids) => (
    <div className={css(styles.horizontalCardsWrapper)}>
      {ids.map((id) => (
        <div className={css(styles.horizontalCardWrapper)}>
          <UserlistFeatureItemContainer
            listId={id}
            toggleLike={toggleListLike}
          />
        </div>
      ))}
    </div>
  );

  const renderTrending = () => (
    <div className={css(styles.trendingSection)} data-id="trending_section">
      {renderFilteredList({
        renderAltCarousel: renderTrendingCardsFullWidth,
        /*
          TODO: This might be better as big items on all sizes instead of a
          carousel, but it will take some more styling work to implement.
        */
        viewMoreLink: "/search/lists?sort=trending",
        headerIcon: faFireAlt,
        title: "Trending Lists",
        listKey: "lists_hub_trending",
      })}
    </div>
  );

  const renderRecentCards = (ids) =>
    ids.map((id) => (
      <div key={id} className={css(styles.listDesktopCardContainer)}>
        <ListDesktopCardContainer key={id} list_id={id} showInfoIcons />
      </div>
    ));

  const renderRecent = () => (
    <div data-id="recent_section">
      {renderFilteredList({
        renderAltCarousel: renderRecentCards,
        viewMoreLink: "/search/lists?sort=recentw",
        headerIcon: faClock,
        title: "Recent Lists",
        listKey: "lists_hub_recent",
      })}
    </div>
  );

  const renderLikedListCards = (ids) =>
    ids.map((id, index) => (
      <div className={css(styles.likedListWrapper)} key={id}>
        {renderConnectedListCardNarrow(id, index, true)}
      </div>
    ));

  const renderLiked = () => {
    const renderedList = renderFilteredList({
      renderAltCarousel: renderLikedListCards,
      viewMoreLink: "/search/lists?sort=ranking",
      headerIcon: faHeartRegular,
      headerType: "secondary",
      title: "Most Liked Lists",
      listKey: "lists_hub_most_liked",
      aside: true,
    });

    return isWindowSizeOrMore("large") ? (
      <div
        className={css(styles.desktopMostLikedSection)}
        data-id="most_liked_section"
      >
        <div className={css(styles.sidebarCTABox)}>
          <CTABox
            title="Create a List"
            buttonLabel="Start Creating"
            handleButtonClick={handleCreateNewListClick}
            content="Create your own curated podcast or episode list to share online."
            analyticsVariables={ctaAnalyticsVariables}
          />
        </div>
        {renderedList}
        {renderNewListButton()}
      </div>
    ) : (
      <div
        className={css(styles.mostLikedSection)}
        data-id="most_liked_section"
      >
        {renderedList}
      </div>
    );
  };

  const canonicalUrl = `${getBaseUrl()}/lists`;
  const { meta, helmetLink } = useMemo(
    () => ({
      meta: generateMetaArray({
        title: "Lists",
        url: canonicalUrl,
        description:
          "Curated lists of podcasts and episodes by the Podchaser community. Trending Lists, Recent Lists, and Most Popular Lists.",
        image: {
          url: `${getBaseUrl()}/images/sharing/podchaser-sharing-lists.jpg`,
        },
      }),
      helmetLink: [{ rel: "canonical", href: canonicalUrl }],
    }),
    [canonicalUrl]
  );

  const renderHeader = () => (
    <HeaderBlock>
      <Helmet title="Podcast and Episode Lists" meta={meta} link={helmetLink} />
      {/* TODO: Might work well here: windowSize.isWindowSizeOrLess('medium')
        && this.renderNewListButton() */}
      <div className={css(styles.headerItemWrapper)}>
        <PageTitle dataId="title-lists" title="Podcast and Episode Lists" />
      </div>
      <div className={css(styles.headerItemWrapper)}>
        <HeaderSubtitle>
          Curated lists allow podcasters and listeners to organize, share and
          bookmark podcasts and episodes.
        </HeaderSubtitle>
      </div>
      <div className={css(styles.searchWrapper)} data-id="lists_hub_search">
        <EntityNavigateSearch
          analyticsVariables={analyticsVariables}
          searchKey="lists_hub_header"
          types={SEARCH_TYPES}
          white
          placeholder="Search for a list..."
          searchStyles={searchStyles}
          dataId="list-search"
          searchFieldStyles={searchFieldStyles}
          autoCompleteStyles={autoCompleteStyles}
        />
      </div>
    </HeaderBlock>
  );

  const renderDesktop = () => (
    <ContentContainer noVerticalPadding overrideStyles={fullWidthStyles}>
      <div className={css(styles.contentWrapper)}>
        <div className={css(styles.listsColumn)}>
          {isMediumOrLess && renderNewListButton()}
          {renderTrending()}
          {renderRecent()}
        </div>
        <div>{renderLiked()}</div>
      </div>
    </ContentContainer>
  );

  const renderMobile = () => (
    <Fragment>
      {isMediumOrLess && renderNewListButton()}
      {renderTrending()}
      {renderRecent()}
      {renderLiked()}
    </Fragment>
  );

  return (
    <Fragment>
      {renderHeader()}
      <div className={css(styles.contentContainerWrapper)}>
        {isMediumOrLess ? renderMobile() : renderDesktop()}
      </div>
    </Fragment>
  );
};

UserListsHub.propTypes = {
  toggleListLike: PropTypes.func.isRequired,
};

export default UserListsHub;
